@media only screen and (max-width: 550px) and (min-width: 320px){
    .container-row{
        height: 100%;
    }
}
@media only screen and (min-width: 551px){
    .form-container{
        width: 550px !important;
    }
}
.history-data-group-div{
    padding: 10px 0px;
}
.history-data-div{
    text-align: initial;
    font-size: 15px;
    font-weight: 700;
    color: #022C4E;
}
.history-data-text{
    font-weight: 400;
}
.no-history-text{
    color: #022C4E;
    font-weight: 400;
    text-align: initial;
    font-size: 15px;
    padding-top: 20px;
}
.back-arrow-img{
    margin-right: 6px !important;
}