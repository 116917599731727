@media only screen and (max-width: 550px) and (min-width: 320px){
    .container-row{
        height: 100%;
    }
}
@media only screen and (min-width: 551px){
    .container-width{
        width: 550px !important;
    }
}
.card{
    //box-shadow: 3px 2px 0px 1px #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 2.6px 1px;
}
.img-row{
    align-self: center;
    width: 200px;
    height: 50px;
}
.container-row{
    height: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 80px !important;
}
.logo-text{
    color: #004c96;
    font-weight: 700;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
}
.container-card{
    padding: 20px 10px !important;
}
.radio-btn{
    padding: 8px;
    color: #022c4e !important;
}
.radio-btn-txt{
    display: flex;
    padding: 5px 0px 0px 10px !important;
    font-size: 14px;
    color: #022c4e !important;
    align-items: baseline;
}
.RadioButton{
    text-align: initial;
    font-size: 15px;
    font-weight: 400;
    color: #022c4e;
    padding-right: 15px;
}
.select-clinic-text{
    color: #022c4e;
    font-size: 14px;
    display: flex;
    padding-left: 30px;
    margin-bottom: 5px !important;
    margin-top: 10px !important;
}
.continue-btn{
    padding-top: 15px;
}
.radio-btn-input{
    color: #022c4e;
}
.clinic-radio{
    border: 2px solid #033761 !important;
}
.clinic-radio:checked {
    background-color: #033761 !important;
    border-color: #033761 !important;
}