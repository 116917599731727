@media only screen and (max-width: 550px) and (min-width: 320px){
    .container-row{
        height: 100%;
    }
}
@media only screen and (min-width: 551px){
    .form-container{
        width: 550px !important;
    }
}
.error-text{
    text-align: initial;
    color: #022C4E;
    font-size: 15px;
}
.error-text-heading{
    text-align: initial;
    color: #004c96 !important;
    font-weight: 600;
    font-size:16px;
    margin-bottom: 10px;
    margin-top: 25px;
}
.back-text-btn{
    width: 100%;
    background-color: white !important;
    border-radius: 20px  !important;
    border: 2px solid #004C96 !important;
    margin-top: 15px;
    padding: 8px;
    color: #004C96 !important;
}