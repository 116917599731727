@media only screen and (max-width: 550px) and (min-width: 320px) {
    .container-row {
        height: 100%;
    }

    .popup-content {
        width: 90% !important;
    }

}

@media only screen and (min-width: 551px) {
    .form-container {
        width: 550px !important;
    }

    .popup-content {
        width: 500px !important;
    }

    .pop-up-container {
        width: 500px !important;
    }
}

.pod-id-div {
    text-align: initial;
    font-size: 16px;
    font-weight: 700;
    color: #004C96;
}

.associated-div {
    text-align: initial;
    padding-top: 20px;
    font-size: 15px;
    font-weight: 700;
    color: #022C4E;
}

.date-div {
    text-align: initial;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #022C4E;
}

.data-div {
    border-bottom: 1px solid #D7D7D7;
}

.clear-data-btn {
    width: 100%;
    background-image: linear-gradient(#3370AB, #004C96, #002E5A) !important; 
    background-color: #004C96 !important;
    border-radius: 20px !important;
    margin-top: 15px;
    padding: 8px !important;
    color: white !important;
}

.big-button {
    background-color: #004C96 !important;
    border-radius: 20px !important;
    margin-top: 15px;
    padding: 8px !important;
    color: white !important;
    width: 100%;      /* Use a specific value like 500px if you don't want it to be full width */
    height: auto;     /* Adjust as needed */
    font-size: 1.5em; /* Larger font size to accommodate more text */
    padding: 15px;    /* More padding for a bigger button */
    white-space: normal; /* Allows the text to wrap inside the button */
    text-align: left; /* Align text to the left */
    white-space: pre-line;
    width: auto;  /* or a specific width if you want, e.g., 400px */
    height: auto; /* make the button's height flexible */
    padding: 10px 20px;
}

.view-history-btn {
    width: 100%;
    font-weight: 400 !important;
    background-color: white !important;
    border-radius: 20px !important;
    border: 2px solid #004C96 !important;
    margin-top: 15px;
    padding: 10px ;
    color: #004C96 !important;
}

.clinic-name-text {
    display: flex;
    flex-direction: column;
    padding-left: 0px !important;
    font-size: 16px;
    font-weight: 700;
    color: #022C4E;
}

.clinic-div {
    flex: 1;
}

.back-btn {
    background: none !important;
    color: #022C4E !important;
    border: 0 !important;
    margin-bottom: 2px !important;
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.back-text {
    font-weight: 600;
    font-size: 16px !important;
}

.message-div {
    color: #022C4E;
    text-align: initial;
    font-weight: 400;
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.associated-text {
    font-weight: 400;
}

.modal {
    font-size: 12px;
}

.header {
    width: 100%;
    font-size: 16px;
    text-align: center;
    padding: 15px;
    color: #022C4E;
    font-weight: 700;
}

.content {
    text-align: center;
    width: 100%;
    padding: 10px 0px;
    font-size: 15px;
    color: #022C4E;
    font-weight: 400;
}

.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.pop-up-container {
    width: 90% !important;
}


.clear-btn-popup {
    width: 70%;
    background-image: linear-gradient(#3370AB, #004C96, #002E5A) !important; 
    border-radius: 20px !important;
    color: white !important;
}

.go-back-btn-popup {
    width: 70%;
    background-color: white !important;
    border-radius: 20px !important;
    border: 2px solid #004C96 !important;
    margin-top: 15px;
    color: #004C96 !important;
}

.process-content {
    text-align: initial;
    width: 100%;
    padding: 10px 0px;
    font-size: 15px;
    color: #022C4E;
    font-weight: 400;
}

.process-content-text {
    padding-left: 50px;
    padding-bottom: 20px;
}

.wait-btn {
    width: 70%;
    background-color: #D1D1D1 !important;
    border-radius: 20px !important;
    color: #444C53 !important;
    font-weight: 600 !important;
    border: 0px !important;
}

.process-btn {
    padding-top: 20px;
    text-align: center;
}

.try-again-btn-popup {
    width: 70%;
    background-color: #004C96 !important;
    border-radius: 20px !important;
    color: white !important;
    border: none !important;
}

.unsucessful-popup-btn {
    text-align: center !important;
}

.unsuccesful-content-text {
    text-align: initial;
    width: 100%;
    padding: 10px 50px;
    font-size: 15px;
    color: #022C4E;
    font-weight: 400;
}

.unsuccesful-ul {
    padding-left: 30px !important;
    color: #003D78 !important;
    font-size: 12px;
    padding-top: 15px;
    font-weight: 400;
}
.back-arrow-img{
    margin-right: 6px !important;
}
.process-img{
    margin-bottom: 2px;
}
.process-text{
    padding-left: 15px;
}
.finish-btn {
    width: 70%;
    background-image: linear-gradient(#3370AB, #004C96, #002E5A) !important;
    border-radius: 20px !important;
    color: white !important;
    margin-left: 70px
}
.try-again-text{
    font-size: 12px;
    font-weight: 400px !important;
    color: #003D78;
    padding: 7px 40px 0px 40px;
}
.loading-div{
    margin-top: 50px;
}