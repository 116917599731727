@media only screen and (max-width: 550px) and (min-width: 320px){
    .container-row{
        height: 100%;
    }
}
@media only screen and (min-width: 551px){
    .form-container{
        width: 550px !important;
    }
}
.info-text{
    text-align: initial;
    color: #022C4E;
    font-size: 15px;
}
.info-text-heading{
    text-align: initial;
    color: #004c96 !important;
    font-weight: 600;
    font-size:16px;
    margin-bottom: 0px;
    margin-top: 25px;

}
.option-div{
    padding-top: 40px;
    font-weight: 600;
    color: #022C4E;
    font-size: 16px;
}
.clinic-name{
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 700;
    color: #022C4E;
}
.logput-btn{
    background: none !important;
    color: #022C4E !important;
    border: 0 !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}
.clinic-name-div{
    flex: 1;
    padding-left: 50px;
}
.points{
    text-align: initial;
    padding-top: 15px;
    color: #022C4E;
}
.card-container{
    border-style: none !important;
    box-sizing: none !important;
    box-shadow: none !important;
}
.card-body{
    display: flex;
    flex-direction: column;
}
.scan-qr-btn{
    width: 100%;
    background-color: #004C96 !important;
    background-image: linear-gradient(#3370AB, #004C96, #002E5A) !important;
    border-radius: 20px  !important;
    border: 0px !important;
    padding: 10px !important;
}
.check-text-btn{
    width: 100%;
    background-color: white !important;
    border-radius: 20px  !important;
    border: 2px solid #004C96 !important;
    margin-top: 15px;
    padding: 10px;
    color: #004C96 !important;
}
.disabled-check-text-btn{
    width: 100%;
    background-color: white !important;
    border-radius: 20px  !important;
    border: 2px solid gray !important;
    margin-top: 15px;
    padding: 10px;
    color: gray !important;
}
.pod-input{
    font-size: 15px !important;
    color: #696F75 !important;
    border-color: #99B7D5 !important;
    padding: 10px !important;
}