@media only screen and (max-width: 550px) and (min-width: 320px){
    .container-row{
        height: 100%;
    }
    .form-horizontal{
        width: 100%;
    }
    .input-field{
        width: 90% !important;
        border-radius: 5px;
        // margin: 0px !important;
        margin-bottom: 10px !important;
    }
    .banner-content{
        width: 100%;
    }
}

@media only screen and (min-width: 551px){
    .container-width{
        width: 550px !important;
    }
    .bottom-text{
        text-align: left;
        font-size: 14px !important;
        color: #022C4E;
        font-weight: 400;
    }
    .banner-content{
        width: 550px;
    }
    
}

.card{
    //box-shadow: 3px 2px 0px 1px #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 2.6px 1px;
}
.img-row{
    align-self: center;
    width: 200px;
    height: 50px;
}
.signin-text{
    color: #022c4e;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    padding-left: 25px;
}
// .form-container{
//     height: 100%;
// }
.container-row{
    height: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 80px !important;
}
.logo-text{
    color: #004c96;
    font-weight: 700;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
}
.form{
    padding: 0px !important;
}
.container-card{
    padding: 20px 10px !important;
}
.form-horizontal{
    width: 100%;
}
.input-field{
    width: 90% !important;
    border-radius: 5px;
    display: inline-flex !important;
    margin-bottom: 10px !important;
}
.input-icon{
    background-color: #EEF4FB !important;
    border-color: #99B7D5 !important;
    border-right: 0px;
}
.icon-img{
    width: 90%;
}
.field-input{
    font-size: 15px !important;
    color: #696F75 !important;
    border-color: #99B7D5 !important;
    border-left: 0px !important;
}
.bottom-text{
    text-align: left;
    font-size: 12px;
    color: #022C4E;
    font-weight: 400;
}
.login-btn{
    justify-content: center !important;
    width: 100% !important;
}
.login-text{
    width: 50%;
    background-image: linear-gradient(#3370AB, #004C96, #002E5A) !important;
    // background-color: #004C96 !important;
    border-radius: 20px  !important;
    border: 0px !important;
    margin-top: 10px;
}
.error-message{
    color: #D92F18;
    font-size: 12px;
    display: flex;
    padding-left: 35px;
    padding-bottom: 10px;
}
.logout-error-message{
        color: #D92F18;
        font-size: 12px;
        display: flex;
        font-weight: 400;
        padding: 15px;
}
.website-link{
    color: #022C4E;
    font-weight: 600;
}
.banner-content{
    background-color: #EEF4FB;
    color: #004C96; 
    padding: 10px 20px 20px 10px;
    font-size: 12px;
    justify-content: center;
}
.banner-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-btn{
        background: none !important;
        color:#004C96 !important;
        border: 0 !important;
        text-align: end;
        font-size: 12px !important;
        font-weight: 700 !important;
        text-decoration: underline !important;
}


